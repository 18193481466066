<template>
  <div></div>
</template>

<script>
import { setItem, getItem, removeItem } from "@/utils/storage";
export default {
  data() {
    return {

      openid: "",
      phone:""
    };
  },
  components: {},
  mounted() {
    this.wxLogin();
  },
  created() {

    let _openid = this.$route.query.openid;
   this.phone=this.$route.query.phone;

    //let _openid='ojgIZwwhIm5S1LwtWx7ZRTzwU4zM';
    if (_openid == "undefined" || _openid == undefined || _openid == "") {
      let hostInfo = window.location.host;
      let vurl=window.location.protocol +
        "//" +
        hostInfo +
        
        "/api/GetOpenID?="+

        "&type=wxlogin";
        alert(vurl)
      window.location.href =vurl;
       
    } else {
      this.openid = _openid;
    }
   alert(`openid值：` + this.openid);
  },
  methods: {
    wxLogin() {
      this.$axios
        .post("/api/Member/WxLogin", {

          openid: this.openid

        })
        .then((res) => {
          if (res.data.Message == "SUCCESS") {
            //console.log("needopenid"+res.data.Data.IsNeedOpenID);
            if(res.data.Data.IsNeedOpenID==1)
            {
                this.$router.push('/bindphone');
            }
            else  
            {

                this.$toast.success("登录成功"); // 如果有redirect则跳转到来源页如果没有跳转到首页

                this.$router.push({name:"order",query:{ phone:this.phone }});
            }

          } else  
          {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
</style>
